var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),(!_vm.readonly())?_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"trainingLocationForm","color":"primary","type":"submit","loading":_vm.isTrainingLocationSubmitted || _vm.isLoadingAccessPlanFile}},[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")]):_vm._e()],1)],2),_c('v-container',{staticClass:"h-100",attrs:{"id":"training_location-list","fluid":"","tag":"section"}},[[_c('div',[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"trainingLocationForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"training_location","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-map-marker"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:"text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t("form.required")))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("training_location.form.titles.training_location"))+" ")])],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.name'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.name'),"outlined":"","name":"name","readonly":_vm.readonly()},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-autocomplete',{staticClass:"ml-3 mb-4",attrs:{"outlined":"","no-filter":"","loading":!!_vm.isLoadingAddress,"items":_vm.itemsAddress,"search-input":_vm.addressSearch,"hide-no-data":"","hide-selected":"","item-value":"properties.label","item-text":"properties.label","error-messages":_vm.errors,"label":_vm.$t('form.address_search'),"return-object":"","readonly":_vm.readonly()},on:{"update:searchInput":function($event){_vm.addressSearch=$event},"update:search-input":function($event){_vm.addressSearch=$event}},model:{value:(_vm.addressSelected),callback:function ($$v) {_vm.addressSelected=$$v},expression:"addressSelected"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.address'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.address'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])}),_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"type":"number","error-messages":_vm.errors,"label":_vm.$t('form.longitude'),"outlined":"","readonly":_vm.readonly()},on:{"input":function($event){_vm.longitude = $event !== '' ? Number($event) : null}},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=_vm._n($$v)},expression:"longitude"}}),_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.city') + '*',"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.web_site'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.web_site'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.webSite),callback:function ($$v) {_vm.webSite=$$v},expression:"webSite"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"ml-3 mb-4",attrs:{"persistent-hint":"","hint":_vm.$t('training_location.form.custom_type') + '*',"error-messages":errors,"items":_vm.$appConfig.trainingLocationTypes,"item-value":"@id","item-text":"name","label":_vm.$t('training_location.form.type') + '*',"outlined":"","readonly":_vm.readonly()},nativeOn:{"input":function($event){return (function (e) { return (_vm.type = e.target.value); })($event)}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.zip_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"maxlength":"5","error-messages":errors,"label":_vm.$t('training_location.form.zip_code') + '*',"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})]}}])}),_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"type":"number","error-messages":_vm.errors,"label":_vm.$t('form.latitude'),"outlined":"","readonly":_vm.readonly()},on:{"input":function($event){_vm.latitude = $event !== '' ? Number($event) : null}},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=_vm._n($$v)},expression:"latitude"}}),_c('SelectArea',{attrs:{"items":_vm.listAreas,"label":_vm.$t('training_location.form.area') + '*',"readonly":_vm.readonly(),"rules":"required","outlined":"","classes":"ml-3 mb-4"},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.opening_time'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.opening_time'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.openingTime),callback:function ($$v) {_vm.openingTime=$$v},expression:"openingTime"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.maximum_capacity'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.maximum_capacity'),"type":"number","outlined":"","readonly":_vm.readonly()},on:{"input":function($event){_vm.maximumCapacity =
                            $event !== '' ? Number($event) : null}},model:{value:(_vm.maximumCapacity),callback:function ($$v) {_vm.maximumCapacity=_vm._n($$v)},expression:"maximumCapacity"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.price'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.price'),"type":"number","outlined":"","readonly":_vm.readonly()},on:{"input":function($event){_vm.price = $event !== '' ? parseFloat($event) : null}},model:{value:(_vm.price),callback:function ($$v) {_vm.price=_vm._n($$v)},expression:"price"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{staticClass:"mb-2 pt-0",attrs:{"label":_vm.$t('training_type.form.access_plan'),"accept":"application/pdf","clearable":"","show-size":"","disabled":!_vm.hasGroupAdmin(),"loading":_vm.isLoadingDownloadAccessPlanFile},on:{"change":_vm.uploadAccessPlan},model:{value:(_vm.accessPlanFile),callback:function ($$v) {_vm.accessPlanFile=$$v},expression:"accessPlanFile"}},[_c('template',{slot:"label"},[(_vm.accessPlan)?_c('span',[_vm._v(" "+_vm._s(_vm.accessPlan.fileName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('training_type.form.access_plan'))+" ")])]),_c('template',{slot:"append-outer"},[(_vm.accessPlan && _vm.hasGroupAdmin())?[_c('v-icon',{staticClass:"mt-1",on:{"click":function($event){return _vm.downloadFile(_vm.accessPlan)}}},[_vm._v(" mdi-download ")]),_c('v-icon',{staticClass:"mt-1 ml-4",on:{"click":function($event){_vm.accessPlan = null; _vm.accessPlanFile = null;}}},[_vm._v(" mdi-delete ")])]:_vm._e()],2)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('select-search-training-type',{attrs:{"clearable":"","outlined":"","return-object":"","multiple":"","hide-details":"","chips":"","persistent-hint":"","rules":"required","classes":"ml-3 mb-4","readonly":_vm.readonly()},model:{value:(_vm.trainingType),callback:function ($$v) {_vm.trainingType=$$v},expression:"trainingType"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.commentary'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-textarea',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.commentary'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.commentary),callback:function ($$v) {_vm.commentary=$$v},expression:"commentary"}})]}}])})],1)],1)],1)],2),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"training_location","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-account-outline"}},[_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("training_location.form.titles.contact"))+" ")])],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.civility_contact'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"items":['M', 'Mme'],"label":_vm.$t('training_location.form.civility_contact'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.civilityContact),callback:function ($$v) {_vm.civilityContact=$$v},expression:"civilityContact"}})]}}])})],1),_c('v-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.last_name_contact'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.last_name_contact'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.lastNameContact),callback:function ($$v) {_vm.lastNameContact=$$v},expression:"lastNameContact"}})]}}])})],1)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.first_name_contact'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.first_name_contact'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.firstNameContact),callback:function ($$v) {_vm.firstNameContact=$$v},expression:"firstNameContact"}})]}}])})],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('phone-number',{attrs:{"classes":"ml-3 mb-4","readonly":_vm.readonly(),"label":_vm.$t('training_location.form.phone_number_contact')},model:{value:(_vm.phoneNumberContact),callback:function ($$v) {_vm.phoneNumberContact=$$v},expression:"phoneNumberContact"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('phone-number',{attrs:{"classes":"ml-3 mb-4","label":_vm.$t('form.secondPhone'),"readonly":_vm.readonly()},model:{value:(_vm.secondPhoneNumberContact),callback:function ($$v) {_vm.secondPhoneNumberContact=$$v},expression:"secondPhoneNumberContact"}})],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_location.form.email_contact'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('training_location.form.email_contact'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.emailContact),callback:function ($$v) {_vm.emailContact=$$v},expression:"emailContact"}})]}}])})],1)],1)],1)],2)],1)],1),_c('ScrollTop'),_c('cancel-update-dialog',{attrs:{"saving":_vm.isTrainingLocationSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }