

























































































































































































































































































































































































































































































  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapState, mapGetters } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
  import { RawLocation } from 'vue-router'
  import TopBar from '@/components/organisms/o-top-bar.vue'
  import axios from 'axios'
  import { isReadonly, userConnectedHasGroup } from '@/composables/UserGrant'
  import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
  import { groupAdmin } from '@/config-constantes'
interface Geometry {
  type: string;
  coordinates: number[];
}

interface Properties {
  label: string;
  score: number;
  id: string;
  name: string;
  postcode: string;
  citycode: string;
  x: number;
  y: number;
  city: string;
  context: string;
  type: string;
  importance: number;
}

interface itemsAddress {
  type: string;
  geometry: Geometry;
  properties: Properties;
}
  export default defineComponent({
    setup () {
      const readonly = () => isReadonly()
      const hasGroupAdmin = () => userConnectedHasGroup(groupAdmin)
      return {
        readonly,
        hasGroupAdmin,
      }
    },
    name: 'TrainingLocation',
    components: {
      SelectSearchTrainingType,
      TopBar,
      CancelUpdateDialog: () =>
      import('../../../components/cancel-update-dialog.vue'),
      ScrollTop: () => import('@/components/base/ScrollTop.vue'),
      SelectSearch: () => import('@/components/molecules/m-select-search.vue'),
      PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
      SelectArea: () => import('@/components/molecules/select/area.vue'),
    },
    data () {
      return {
        title: this.$t('training_location.title'),
        errors: [],
        itemsAddress: [] as itemsAddress[],
        addressSearch: '',
        isLoadingAddress: 0,
        addressSelected: null as itemsAddress | null,
        accessPlanFile: null,
      }
    },
    mixins: [cancelSaveDialog],
    computed: {
      // The `mapFields` function takes an array of
      // field names and generates corresponding
      // computed properties with getter and setter
      // functions for accessing the Vuex store.
      ...mapFields('trainingLocationForm', [
        'trainingLocation.name',
        'trainingLocation.address',
        'trainingLocation.zipCode',
        'trainingLocation.city',
        'trainingLocation.area',
        'trainingLocation.webSite',
        'trainingLocation.openingTime',
        'trainingLocation.type',
        'trainingLocation.maximumCapacity',
        'trainingLocation.price',
        'trainingLocation.trainingType',
        'trainingLocation.commentary',
        'trainingLocation.civilityContact',
        'trainingLocation.lastNameContact',
        'trainingLocation.firstNameContact',
        'trainingLocation.phoneNumberContact',
        'trainingLocation.secondPhoneNumberContact',
        'trainingLocation.emailContact',
        'trainingLocation.longitude',
        'trainingLocation.latitude',
        'trainingLocation.accessPlan',
      ]),
      ...mapState('area', {
        listAreas: 'list',
      }),
      ...mapState('trainingLocationForm', {
        isTrainingLocationSubmitted: 'isSubmitted',
        isFormChanged: 'isChanged',
        trainingLocation: 'trainingLocation',
      }),
      ...mapState('file', {
        isLoadingAccessPlanFile: 'loading',
        isLoadingDownloadAccessPlanFile: 'loadingDownload',
      }),
      ...mapGetters('navigation', {
        getLastSavedRoute: 'getLastSavedRoute',
      }),
      idTrainingLocation () {
        return this.$route.params.idTrainingLocation || null
      },
    },
    async created () {
      await this.init()
    },
    watch: {
      idTrainingLocation () {
        this.init()
      },
      addressSearch () {
        this.getAddress()
      },
      addressSelected () {
        if (this.addressSelected !== null) {
          this.address = this.addressSelected.properties.name
          this.zipCode = this.addressSelected.properties.postcode
          this.city = this.addressSelected.properties.city
          this.longitude = this.addressSelected.geometry.coordinates[0]
          this.latitude = this.addressSelected.geometry.coordinates[1]
        }
      },
    },
    methods: {
      ...mapActions('file', {
        uploadFile: 'upload',
        downloadFile: 'download',
      }),
      async init () {
        await this.reset()
        if (this.idTrainingLocation) {
          this.traininglocation = await this.loadTrainingLocationById(
            this.idTrainingLocation,
          )
        }
      },
      getAddress () {
        // string without space minimum length 3
        if (this.addressSearch && this.addressSearch.replace(/\s+/g, '').length > 2) {
          this.isLoadingAddress++
          axios
            .get(
              `https://api-adresse.data.gouv.fr/search?q=${this.addressSearch}&limit=10`,
            )
            .then((response) => {
              this.itemsAddress = response.data.features
            }).finally(() =>
              this.isLoadingAddress--,
            )
        }
      },
      ...mapActions('trainingLocationForm', {
        save: 'save',
        duplicate: 'duplicate',
        reset: 'reset',
        loadTrainingLocationById: 'loadById',
      }),
      ...mapActions('user', {
        sendSuccessMessage: 'sendSuccessMessage',
      }),
      async uploadAccessPlan () {
        if (this.accessPlanFile) {
          this.accessPlan = await this.uploadFile(this.accessPlanFile)
        } else {
          this.accessPlan = null
          this.accessPlanFile = null
        }
      },
      async onSubmit () {
        const isValidatedForm = await (this as any).isValidatedForm(
          this.$refs.validationObserver,
        )
        if (isValidatedForm) {
          if (this.$route.meta.duplicate) {
            await this.duplicate(this.trainingLocation)
            await this.sendSuccessMessage('training_location.form.duplicated')
          } else {
            await this.save(this.trainingLocation)
            await this.sendSuccessMessage('training_location.form.saved')
          }
          this.returnPrevious()
        }
      },
      cancel () {
        this.returnPrevious()
      },
      returnPrevious () {
        if (this.getLastSavedRoute) {
          this.$router.push(this.getLastSavedRoute as RawLocation)
        } else {
          this.$router.push({ name: 'TrainingLocation List' })
        }
      },
    },
  })
